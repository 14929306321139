@import "/home/vsts/work/1/s/node_modules/@pidoc/core/src/defaults/variables.scss";
@import "../../../../node_modules/@pidoc/core/src/styles/variables";

footer {
  padding: 1em;
  background: $docs-color-secondary;
}

footer .footer {
  padding: 1rem 0;

  .logo {
    max-width: 180px;
  }

  .footer-columns {
    flex-direction: column;

    & > div {
      flex: 1;

      @media (max-width: $docs-tablet-brk) {
        &:first-child {
          flex: 1 100%;
        }
      }
    }

    @media (min-width: $docs-mobile-brk) {
      flex-direction: row;
    }
  }

  .footer-menu {
    margin-top: 3rem;
    padding-left: 1em;

    @media (min-width: $docs-tablet-brk) {
      &:first-child {
        margin-top: 1rem;
      }
    }

    h3 {
      color: white;
      font-weight: bold;
      margin-top: 0;
      text-transform: uppercase;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      > li {
        margin: 1rem 0;

        a {
          color: white;
          text-decoration: none;

          &:hover {
            color: $docs-color-primary;
          }
        }
      }
    }
  }

  .copy-rights {
    color: white;
    margin: 0 auto;
    font-weight: 300;
    margin-top: 2rem;
    margin-bottom: 0;
    padding: 0 1em;
    line-height: 1.8;


    .fa.fa-heart {
      color: $docs-color-heart;
      padding: 0 0.2em;
    }

    @media (min-width: $docs-mobile-brk) {
      text-align: center;
    }
  }
}
