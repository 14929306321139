@import "/home/vsts/work/1/s/node_modules/@pidoc/core/src/defaults/variables.scss";
@import '../../../../node_modules/@pidoc/core/src/styles/variables';

.image-card {
  flex: 0 0 194px !important;
}

.deploy-guides {
  display: inline-grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 0.5em;

  > p {
    display: flex;
    justify-content: center;
    align-items: center;

    > a {
      flex: 1;
    }
  }
}

body.dark {
  h2.plugin {
    color: $docs-color-text-dark;
  }

  .markdown-body img {
    background-color: unset;
  }

  .card.image-card {
    color: $docs-color-text-dark;
  }

  .tabs .tabs-header ul li a.active {
    color: $docs-color-text-dark;
  }

  .type-title {
    background: $docs-color-shadow-dark;

    & > b {
      color: transparentize($color: $docs-color-text-dark, $amount: 0.2);
    }
  }
}

.version-info {
  flex: 1 0 290px !important;
}

.plugin-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 64px;
    height: 64px;
  }

  h1 {
    flex: 1;
    font-size: 2em;
    margin-left: 0.5em;
  }

  &.markdown-body h1 {
    margin-left: 0;
  }
}

h2.plugin {
  font-size: 1.4em;
  color: $docs-color-tabs-text-normal;
  margin-top: 2em;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 40%);
  z-index: 101;

  .search {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1em;
    width: 40em;
    max-width: 100%;
    height: 50em;
    max-height: 100%;
    box-sizing: border-box;
    margin: auto;
  }

  .search-input {
    position: relative;
    width: 100%;

    input[type=search], input[type=text] {
      background: #fdfdfd;
      padding: 1rem 2.5rem 1rem 1rem;
      border: none;
      box-sizing: border-box;
      width: 100%;
      color: #333
    }

    i {
      position: absolute;
      color: #333;
      top: 0;
      bottom: 0;
      right: 0.8em;
      margin: auto;
      height: 1em;
    }
  }

  .search-results {
    list-style: none;
    padding: 0;
    margin: 0;
    background: $docs-color-secondary;
    color: #fff;

    li {
      display: block;
      padding: 1em;

      span {
        display: block;
      }

      .title {
        font-weight: bold;
      }

      .url {
        font-size: 0.6em;
        color: #bbb;
      }

      .keywords {
        font-size: 0.9em;
        color: #eee;
        margin-top: 0.2em;
      }
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: $docs-color-primary;
      }
    }
  }
}
